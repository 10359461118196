<template>
  <div>
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>Restablecer contraseña</v-toolbar-title>
      </v-toolbar>

      <v-container>
        <v-row>
          <v-col cols="12">
            <div>
              <strong>{{ usuario.nombre }}</strong>
              ingresa tu nueva contraseña:
              <br /><br />
            </div>
            <v-text-field
              v-model="password1"
              id="password1"
              prepend-inner-icon="mdi-lock"
              name="login_password1"
              label="Contraseña"
              type="password"
              outlined
              dense
              :disabled="loading || passwordModificado"
              :error-messages="errors.password1"
              @input="validarPassword1()"
              @blur="validarPassword1()"
            >
            </v-text-field>
            <v-text-field
              v-model="password2"
              id="password2"
              prepend-inner-icon="mdi-lock"
              name="login_password2"
              label="Repite contraseña"
              type="password"
              outlined
              dense
              :disabled="loading || passwordModificado"
              :error-messages="errors.password2"
              @input="validarPassword2()"
              @blur="validarPassword2()"
            >
            </v-text-field>
            <div v-if="mensajeError" class="error-text">{{ mensajeError }}</div>
          </v-col>
        </v-row>
      </v-container>

      <v-divider class="mx-3"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="
            password1.length == 0 || password2.length == 0 || passwordModificado
          "
          @click="resetPassword()"
        >
          Restablecer
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="passwordModificado" width="420">
      <v-card>
        <v-card-title
          ><h5>Contraseña actualizada correctamente</h5></v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined @click="$router.push('/login')"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";

import { restablecerPass } from "./login.service";

export default {
  name: "restablecerPassForm",
  props: ["usuario"],
  mixins: [validationMixin],
  validations: {
    password1: { required, minLength: minLength(5) },
    password2: {
      required,
      sameAs: sameAs("password1"),
      minLength: minLength(5),
    },
  },
  data() {
    return {
      loading: false,
      token: "",
      password1: "",
      password2: "",
      mensajeError: null,
      passwordModificado: false,
      errors: {
        password1: [],
        password2: [],
      },
    };
  },
  mounted() {
    this.token = this.$route.params.token;
  },
  methods: {
    validarPassword1() {
      const errors = [];
      this.$v.password1.$touch();
      if (!this.$v.password1.$dirty) return errors;
      !this.$v.password1.required && errors.push("Campo requerido.");
      !this.$v.password1.minLength && errors.push("Mínimo 5 caracteres.");
      this.errors.password1 = errors;
    },
    validarPassword2() {
      const errors = [];
      this.$v.password2.$touch();
      if (!this.$v.password2.$dirty) return errors;
      !this.$v.password2.required && errors.push("Campo requerido.");
      !this.$v.password2.minLength && errors.push("Mínimo 5 caracteres.");
      !this.$v.password2.sameAs &&
        errors.push("Las contraseñas deben ser iguales.");
      this.errors.password2 = errors;
    },
    formularioValido() {
      this.validarPassword1();
      this.validarPassword2();
      if (this.errors.password1.length > 0) return false;
      if (this.errors.password2.length > 0) return false;
      return true;
    },
    async resetPassword() {
      if (this.formularioValido())
        try {
          const sendData = {
            token: this.token,
            idUsuario: this.usuario.idUsuario,
            password: this.password1,
          };
          this.mensajeError = null;
          this.loading = true;
          const serverResponse = await restablecerPass(sendData);
          this.$validateResponse(serverResponse);
          this.loading = false;
          if (!serverResponse.ok) {
            this.correoErrors = [serverResponse.mensaje];
          } else {
            this.passwordModificado = true;
          }
        } catch (error) {
          this.loading = false;
          this.$appError(error.mensaje);
        }
    },
  },
};
</script>

<style>
.error-text {
  color: red;
}
</style>
