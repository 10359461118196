<template>
  <v-app>
    <v-container fluid class="fill-height" id="login-bg">
      <v-row align="center" justify="center">
        <v-col cols="11" sm="8" md="6" lg="3">
          <v-card v-if="loading" class="elevation-12">
            <v-card-title v-if="loading">
              <h3>Cargando información...</h3>
            </v-card-title>
            <v-card-title v-if="loading">
              <v-progress-linear indeterminate height="20"></v-progress-linear>
            </v-card-title>
          </v-card>

          <v-card v-if="!loading && !enlaceValido">
            <v-divider class="mx-3"></v-divider>
            <v-card-title>
              <h3>Ooops... 😥</h3>
            </v-card-title>
            <v-card-text>
              El enlace para restaurar no es válido
            </v-card-text>
          </v-card>

          <restablecerPassFormComponent
            v-if="enlaceValido"
            :usuario="usuario"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>

import restablecerPassFormComponent from "./restablecerPassForm.vue";

import { validarTokenParaRestablecerPass } from "./login.service";

export default {
  name: "restablecerPasswordForm",
  components: { restablecerPassFormComponent },
  data() {
    return {
      loading: true,
      error: false,
      token: null,
      enlaceValido: false,
      usuario: {},
    };
  },
  mounted() {
    this.token = this.$route.params.token;
    this.validarToken();
  },
  methods: {
    async validarToken() {
      try {
        this.loading = true;
        const serverResponse = await validarTokenParaRestablecerPass(this.token);
        this.$validateResponse(serverResponse);
        this.loading = false;
        if (!serverResponse.ok) {
          this.enlaceValido = false;
        } else {
          this.usuario = serverResponse.usuario;
          this.enlaceValido = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.flat-link {
  text-decoration: none;
}
#login-bg {
  height: 100vh;
  width: 100vw;
  background-color: #0d47a1;
  background-image: linear-gradient(10deg, #81d4fa 50%, transparent 50%),
    linear-gradient(-60deg, #5e35b1 30%, transparent 30%);
}
</style>
